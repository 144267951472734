import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAsyncData } from "./api";
import { getNestedObjValue } from "@ui/Utils/helper";
import { Checkbox } from "@material-ui/core/index";
import enums from "helpers/enums";
import { useFormContext } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RHAsyncAutoComplete = ({
  rules,
  label,
  name,
  apiUrl,
  filterOptionLabelKey, // To map options from API to an array of strings, send the object key to show as options
  setOriginalOptions, // filterOptionLabelKey prop is used to filter options as strings and if original API data is required component level, this is set.
  apiMethod = "post",
  apiBody,
  renderFunc,
  renderOption,
  render,
  groupBy,
  extraOptions,
  required = false,
  defaultValue,
  inputRef, // inputRef.focus()
  autoFocus,
  dynamicAPIbody, // if you need dynamic behavior of loading data of dropdown on every open, send this as true...
  ...props
}) => {
  apiBody =
    apiBody && Object.keys(apiBody).find((key) => apiBody[key]) && apiBody;

  const formMethods = useFormContext();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (apiBody && dynamicAPIbody) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        let response = await getAsyncData(apiUrl, {
          apiMethod,
          apiBody,
        });

        if (filterOptionLabelKey) {
          setOriginalOptions(response);
          response = response.map((ele) =>
            getNestedObjValue(ele, filterOptionLabelKey)
          );
        } else if (setOriginalOptions) {
          setOriginalOptions(response);
        }
        // user will either send groupBy or filterOptionLabelKey
        if (groupBy) {
          response.sort((a, b) => {
            return (
              getNestedObjValue(a, groupBy)?.[groupBy]?.toString() -
              getNestedObjValue(b, groupBy)?.[groupBy]?.toString()
            );
          });
        }

        if (active) {
          setOptions(response);
        }
      } catch (err) {
        console.log(err);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const myRenderOption = (option, state) => {
    // if (name === "includedUsers") {
    //   debugger;
    // }

    if (typeof renderOption === "function") {
      return renderOption(option, state);
    } else if (renderOption === enums.keywords?.selectAll) {
      const values = formMethods.getValues();

      if (option[render] === enums.keywords?.selectAll) {
        if (values[name]?.length === options?.length - (extraOptions || 1)) {
          state.selected = true;
        } else {
          state.selected = false;
        }

        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              // style={{}}
              checked={state.selected}
            />
            {option[render] || option[name]}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {(renderFunc && renderFunc(option)) ||
              option[render] ||
              option[name]}
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          {(renderFunc && renderFunc(option)) ||
            option[render] ||
            option?.name ||
            option ||
            ""}
        </React.Fragment>
      );
    }
  };

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            {...props}
            open={open}
            renderOption={renderOption && myRenderOption}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={options || []}
            onChange={(event, value) => {
              if (props.onChange) {
                props.onChange(event, value, options);
              } else {
                field.onChange(value);
              }
            }}
            groupBy={(option) => option?.[groupBy]}
            value={
              props.value || props.multiple
                ? field.value
                  ? field.value
                  : []
                : field.value
                ? field.value
                : null
            }
            loading={loading}
            size="small"
            renderInput={(props) => (
              <TextField
                {...props}
                autoFocus={autoFocus}
                inputRef={inputRef}
                required={required}
                helperText={fieldState?.error && fieldState?.error?.message}
                error={Boolean(fieldState?.error)}
                size="small"
                variant="outlined"
                label={label}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {props.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
};

export const freeSoloReasonConstants = {
  selected: "select-option",
  created: "create-option",
  removed: "remove-option",
};
export default RHAsyncAutoComplete;
