import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth } from "@ui/components/AuthProvider";

import {
  createRecord,
  getByCode,
  paginate,
  search,
  updateRecord,
  uploadFiles,
} from "@ui/ComponentUtils/blueprintAPIs";

import _ from "lodash";

import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import enums from "helpers/enums";
import { MySection } from "@ui/MuiComponents/index";
import MatTable from "@ui/components/MatTable";
import {
  handleGlobalUserControl,
  isDivisionControlled,
} from "@ui/Utils/helper";
import commonConfig from "config/commonConfig";

// const GlobalStyle = createGlobalStyle`
//    body {
//     background: linear-gradient(
//     180deg,
//     rgba(255, 88, 88, 0.1) 0%,
//     rgba(123, 166, 229, 0.31) 69.79%
//   );
//     font-family: 'Advent Pro', sans-serif;
//     min-height: 100vh;
//     padding-inline: 2vh;
//     overflow: auto;
//   }

// `;

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

const UserDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();

  const params = useParams();

  const [divisions, setDivisions] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  const values = formMethods.watch();

  useEffect(() => {
    if (isDivisionControlled) {
      (async () => {
        try {
          const res = (await search(enums.models.divisions)) || [];
          setDivisions(res);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const code = params?.code;

      try {
        if (code == "create") {
          setIsEditable(true);
        } else {
          const res = await getByCode(enums.models.users, code);
          formMethods.reset(res);
          setIsEditable(false);
        }
      } catch (err) {
        navigate(enums.models.users);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      // if (values.password?.toString()?.trim()) {
      //   values.changePasswordFlag = true;
      // }

      let action = "Created",
        res = {};
      if (!values.code) {
        res = await createRecord(enums.models.users, values);
        navigate(`/${enums.models.users}/${res?.code}`);
      } else {
        res = await updateRecord(enums.models.users, values);
        action = "Updated";
      }
      formMethods.reset(res);
      setIsEditable(false);
      CustomEventEmitter.emit("alert_success", "Successfully " + action);
    } catch (err) {
      console.log(err?.response?.data?.message);

      let message = err?.response?.data?.message
        ?.toString()
        .match("phone_1 dup key")
        ? "User exists with Phone: " + values.phone
        : "";

      CustomEventEmitter.emit("alert_error", message || "Something went wrong");
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.id === "create") {
        navigate("/" + enums.models.users);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "firstName",
      label: "First Name",
      fieldType: "textField",
    },
    {
      name: "lastName",
      label: "Last Name",
      fieldType: "textField",
    },
    {
      name: "division",
      label: "Division",
      fieldType: "dropdown",
      options: divisions || [],
      multiple: true,
      getOptionLabel: (option) => option?.name || "",
      hidden: !isDivisionControlled,
      render: "name",
    },
    {
      name: "password",
      label: "Password",
      fieldType: "textField",
      // type: "password",
      hash: true,
    },
    {
      name: "phone",
      label: "Phone",
      fieldType: "textField",
    },
    {
      name: "email",
      label: "Email",
      fieldType: "textField",
    },
    {
      name: "role",
      label: "Role",
      fieldType: enums.fieldTypes.dropdown,
      options: [
        enums.roles.branch,
        enums.roles.manager,
        enums.roles.zoneManager,
        enums.roles.inventoryManager,
        enums.roles.salesPerson,
        enums.roles.customer,
      ],
    },
  ];

  const links = [
    {
      name: "Users",
      url: "#/" + enums.models.users,
      isDisplayLink: true,
    },
    {
      name: values.firstName + " " + values.lastName,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
    {
      label: "Signout User",
      hidden: isEditable,
      iconName: "signout",
      tooltip: "Signout " + values.firstName,
      onClick: async () => {
        await handleGlobalUserControl(
          {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
          },
          [commonConfig.appModule]
        );

        window.location.reload();
      },
      color: "primary",
    },
    {
      hidden: isEditable,
      label: values.disabled ? "Approve User" : "Disable User",
      iconName: values.disabled ? "disable" : "update",
      tooltip: values.disabled ? "Approve User" : "Disable User",
      onClick: async () => {
        await handleGlobalUserControl(
          {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
            iamControl: { disabled: !values.disabled },
          },
          [commonConfig.appModule]
        );

        window.location.reload();
      },
      color: "primary",
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Header links={links} pageTitle={"Users"} actions={actions} />

      <GeneralSection
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </FormProvider>
  );
};

export default UserDetail;
