import { Dialog, Grid } from "@material-ui/core/index";
import { filterDivisionsWithUserAccess } from "@ui/Auth/api";
import { dynamicApiCall } from "@ui/ComponentUtils/blueprintAPIs";
import { MyDialogContent, MyDialogTitle } from "@ui/MuiComponents/index";
import { userDetails } from "@ui/Utils/helper";
import commonConfig from "config/commonConfig";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useAuth } from "../AuthProvider";

const DivisionControl = ({ userInfo }) => {
  const auth = useAuth();
  const isDivisionControlled =
    userInfo?.client?.premiumClient?.divisionControl ||
    userDetails?.client?.premiumClient?.divisionControl;
  const [divisions, setDivisions] = useState([]);
  const [openDivisionModal, setOpenDivisionModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const userInfo = auth?.user;
    let division = window.localStorage.getItem("division");
    division = division && JSON.parse(division);
    const urlPaths = location.pathname?.toString().split("/");

    if (
      !(
        urlPaths.length < 3 ||
        urlPaths.find((path) => path?.toLowerCase() === "home")
      ) &&
      userInfo?._id &&
      !division?._id
    ) {
      if (isDivisionControlled && auth?.token && divisions?.length === 0) {
        (async () => {
          try {
            const res =
              (await dynamicApiCall(
                `${commonConfig.baseApiUrl}data-engine/divisions/search`,
                "post"
              )) || [];

            const finalData = filterDivisionsWithUserAccess(res, userInfo);
            setDivisions(finalData);

            if (finalData?.length) {
              setOpenDivisionModal(true);
            }
          } catch (err) {
            console.log(err);
          }
        })();
      }
    }
  }, [auth.user, auth.token, location.pathname, isDivisionControlled]);

  if (openDivisionModal) {
    return (
      <Dialog open={openDivisionModal} maxWidth="lg">
        <MyDialogTitle>Select Division</MyDialogTitle>
        <MyDialogContent>
          <Grid container spacing={4}>
            {divisions.map((div, divIndex) => (
              <Grid
                item
                xs={3}
                container
                justifyContent="center"
                key={divIndex}
              >
                <div
                  style={{ margin: "auto", cursor: "pointer" }}
                  onClick={() => {
                    localStorage.setItem("division", JSON.stringify(div));
                    window.location.reload();
                  }}
                >
                  {/* <img
        src={adminPanel}
        alt="img"
        style={{ height: 497.77, width: 606 }}
      /> */}
                  <h3 style={{ padding: "24px", fontSize: 20 }}>{div.name}</h3>
                </div>
              </Grid>
            ))}
          </Grid>
        </MyDialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default DivisionControl;
