import { checkIfImageExists, userDetails } from "@ui/Utils/helper";
import HTTP from "@ui/Utils/httpWithoutLoading";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";

export const chunkImagesUpload = async (files) => {
  try {
    const model = enums.models.training;
    let url =
      commonConfig.microservices.upload +
      model +
      "/imagesToCortex?replace=true";

    const res = await HTTP.upload(url, files);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCortexImages = async () => {
  try {
    let url = `${commonConfig.microservices.cortex}image_check/?client_id=${userDetails?.client?._id}`;

    const res = await HTTP.post(url);

    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const pushToCortex = async (is_first_time) => {
  try {
    let url = `${commonConfig.microservices.cortex}push_to_cortex/?client_id=${userDetails?.client?._id}&is_first_time=${is_first_time}`;

    const res = await HTTP.post(url);

    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const uploadToMyCatalog = async (files) => {
  try {
    let url = `${commonConfig.microservices.cortex}uploadfile/?client_id=${userDetails?.client?._id}`;

    let formData = new FormData();
    files.map((file) => formData.append("file_upload", file));

    const res = await HTTP.upload(url, files, false, formData);

    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteCortexCache = async () => {
  try {
    let url = `${commonConfig.microservices.cortex}delete_dir/?client_id=${userDetails?.client?._id}`;

    const res = await HTTP.post(url);

    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const appendFileNames = async (fileNames) => {
  try {
    let url = `${commonConfig.baseApiUrl}c/${enums.models.candidateImages}/appendFileNames`;

    const res = await HTTP.post(url, { fileNames });

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
