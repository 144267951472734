const enums = {
  stockStatus: {
    staged: "Staged",
    inventory: "In Inventory",
    approval: "In Approval",
    issued: "Issued",
    unAvailable: "Unavailable",
  },

  keywords: {
    selectAll: "Select All",
    includedUsers: {
      public: "All Unregistered Users",
      private: "All Registered Users",
    },
    dataEngine: "Data-Engine",
    cortex: "Cortex",
  },

  roles: {
    superAdmin: "Super Admin",
    admin: "Admin",
    manager: "Manager",
    branch: "Branch",
    zoneManager: "Zone Manager",
    inventoryManager: "Inventory Manager",
    salesPerson: "Sales Person",
    vendor: "Vendor",
    customer: "Customer",
    regular: "Regular",
  },

  fieldTypes: {
    textField: "textField",
    dropdown: "dropdown",
    asyncDropdown: "asyncDropdown",
    upload: "upload",
  },
  dataTypes: {
    string: "string",
    number: "number",
    object: "object",
    array: "array",
  },
  inputTypes: {
    number: "number",
    text: "text",
  },
  localStorage: {
    oneauthlogin: "oneauthlogin",
    top_matches_limit: "top_matches_limit",
    selectedDisplayStyle: "selectedDisplayStyle",
  },

  models: {
    iam: {
      clients: "iam/clients",
      users: "iam/users",
    },
    users: "cortex/users",
    training: "cortex/training",
    identify: "cortex/identify",
    divisions: "data-engine/divisions",
    candidateImages: "cortex/candidate-images",
  },

  productsImportKey: {
    sku: "SKU",
    collectionLine: "Collection Line",
    category: "Category",
    subCategory: "Sub Category",
    styleNo: "Style No",
    grossWeight: "Gross Weight",
    lessWeight: "Less Weight",
    stockStatus: "Stock Status",
  },

  stockLevelField: {
    stoneWeight: "Stone Weight",
    centerStoneWeight: "Center Stone Weight",
    chainWeight: "Chain Weight",
    motiWeight: "Moti Weight",
    motiPieces: "Moti Pieces",
    kundunWeight: "Kundu Weight",
    kundunPieces: "Kundu Pieces",
    actualGrossWeight: "Actual Gross Weight",
    color: "Color",
    purity: "Purity",
    vendorCode: "Vendor Code",
    zone: "Zone",
    totalDiamondWeight: "Total Diamond Weight",
  },

  diamondImportKey: {
    quality: "Quality",
    color: "Color",
    cut: "Cut",
    clarity: "Clarity",
    quantity: "Quantity",
    sizeCode: "Size Code",
    sieveSize: "Sieve Size",
    shape: "Shape",
    stoneType: "Stone Type",
    totalCarat: "Total Carat",
    ratePerCarat: "Rate Per Carat",
  },

  businessType: {
    catalogist: "Catalogist",
    stockist: "Stockist",
  },
};

export default enums;
