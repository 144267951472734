import React, { useEffect, useState } from "react";
import { getCortexImages } from "views/Training/api";
import { Grid } from "@material-ui/core";
import { getCortexImageUri } from "@ui/Utils/helper";
import { getSingleRecord } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import { MyCarousel } from "@ui/MuiComponents/index";

const CatalogDetail = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const candidateImages =
          (await getSingleRecord(enums.models.candidateImages)) || {};
        const res = await getCortexImages();

        const tempMatches = [];

        (res || []).map((styleNo) => {
          let imageFlag = false,
            fileName = styleNo + ".png";

          (candidateImages.fileNames || []).find((eFileName) => {
            if (eFileName?.replace(/\.[^/.]+$/, "") === styleNo) {
              imageFlag = true;
              fileName = eFileName;
              return true;
            } else if (
              eFileName?.replace(/\.[^/.]+$/, "") === styleNo?.split(".")?.[0]
            ) {
              imageFlag = true;
              fileName = eFileName;
            }
          });

          tempMatches.push({
            fileName: fileName,
            imageFlag: imageFlag,
            model: "training",
          });
        });

        setImages(tempMatches);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <h3>Loading...</h3>;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container justify="flex-end">
        <h3>{images.length}</h3>
      </Grid>
      {images.map((image) => (
        <Grid item xs={3} style={{ padding: 16 }}>
          <img
            src={getCortexImageUri(image)}
            style={{ height: 300, width: 320 }}
            alt=""
            loading="lazy"
            onClick={() =>
              setOpenPreview([
                {
                  fileName: image.fileName,
                  fileType: "image/*",
                  location: getCortexImageUri(image),
                },
              ])
            }
          />
          {image?.fileName?.replace(/\.[^/.]+$/, "")}
        </Grid>
      ))}

      {openPreview?.length ? (
        <MyCarousel
          items={openPreview}
          openPreview={openPreview?.length}
          setOpenPreview={() => setOpenPreview([])}
        />
      ) : null}
    </Grid>
  );
};

export default CatalogDetail;
