import React, { useState } from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

const Loader = () => {
  const [loading, setLoading] = useState(0);

  React.useEffect(() => {
    CustomEventEmitter.on("progressBar", (value) => {
      setLoading((prev) => (value ? prev + 1 : prev - 1));
    });

    return () => {
      CustomEventEmitter.off("progressBar");
    };
  });

  if (!loading) return <></>;
  return (
    <div
      style={{
        position: "absolute",
        padding: "-2vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        background: "#bbb",
        margin: "-1vh",
        opacity: 0.6,
        zIndex: 1000,
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  );
};

export default Loader;
