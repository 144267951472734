import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import closeIcon from "../../../assets/ComponentIcons/close.png";
import correctIcon from "../../../assets/ComponentIcons/correct.png";

const ImageUpProgress = ({ reportArray = [] }) => {
  return reportArray.length ? (
    <TableContainer component={Paper}>
      <Table sx={{}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Design Number</TableCell>
            {/* <TableCell align="right">Progress</TableCell> */}
            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportArray.map((row, i) => (
            <TableRow
              key={i}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.fileName}
              </TableCell>
              {/* <TableCell align="right">{ }</TableCell> */}
              <TableCell>
                <img
                  src={correctIcon} //row?.success ? : closeIcon
                  style={{ height: 50, width: 50 }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default ImageUpProgress;
