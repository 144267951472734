import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import axios from "axios";
import { isDivisionControlled, resizeFile } from "./helper";

export const requestHeaders = (additionalHeaders) => {
  const token = window.localStorage.getItem("token");
  const oneauthlogin = window.localStorage.getItem("oneauthlogin");

  const headers = {
    ...additionalHeaders,
  };

  if (oneauthlogin === "true") {
    headers.oneauthlogin = true;
  }

  if (token && token !== "undefined" && token !== "null") {
    headers.Authorization = token;
  }

  if (isDivisionControlled || additionalHeaders?.isDivisionControlled) {
    delete headers.isDivisionControlled;

    headers.division = window.localStorage.getItem("division");
    headers.isdivisioncontrolled = true;
  }

  return headers;
};

const get = async (url, data, additionalHeaders) => {
  try {
    const headers = requestHeaders(additionalHeaders);

    const res = await axios.get(url, { headers });
    return res;
  } catch (err) {
    if (err.response?.status === 403) {
      window.localStorage.clear();
      window.location.reload();
    }

    console.log(err?.response?.data?.message);
    throw err;
  }
};

const post = async (url, data, additionalHeaders) => {
  try {
    const headers = requestHeaders(additionalHeaders);

    const res = await axios.post(url, data, { headers });
    return res;
  } catch (err) {
    if (err.response?.status === 403) {
      window.localStorage.clear();
      window.location.reload();
    }
    console.log(err?.response?.data?.message);

    throw err;
  }
};

export const put = async (url, data, { additionalHeaders } = {}) => {
  try {
    const headers = requestHeaders(additionalHeaders);

    const response = await axios.put(url, data, { headers });
    return response;
  } catch (error) {
    console.log("Error in put method of HTTP" + error?.response?.data?.message);
    throw error;
  }
};

export const deleteRequest = async (url, data) => {
  try {
    const headers = requestHeaders();
    const response = await axios.delete(url, { headers, data });
    return response;
  } catch (error) {
    console.log(
      "Error in deleteRequest method of HTTP" + error?.response?.data?.message
    );
    throw error;
  }
};

const upload = async (url, files, resize = true, overRideForm) => {
  let response = {},
    headers = requestHeaders(headers);
  if (overRideForm) {
    response = await axios.post(url, overRideForm, {
      headers,
    });
  } else {
    let formData = new FormData();
    formData.append("files", files);

    try {
      for (let i = 0; i < files.length; i++) {
        if (files[i]?.type?.includes("image")) {
          const imageFile = resize ? await resizeFile(files[i]) : files[i];
          formData.append("files", imageFile);
        } else {
          formData.append("files", files[i]);
        }
      }

      response = await axios.post(url, formData, {
        headers,
      });
    } catch (err) {
      console.log(
        "Error in upload method of HTTP" + err?.response?.data?.message
      );
      throw err;
    }
  }

  return response;
};

axios.interceptors.request.use(
  (config) => {
    CustomEventEmitter.emit("progressBar", true);
    // check for valid session / refreshed Token to current session.
    return config;
  },
  (error) => {
    console.error(error.message);
    throw error;
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    CustomEventEmitter.emit("progressBar", false);

    return response;
  },
  (error) => {
    console.error(error.message);
    if (error.response?.status == 403) {
      CustomEventEmitter.emit(
        "alert_error",
        "Session expired. Login to continue..."
      );

      window.localStorage.clear();
      window.location.reload();
    }
    CustomEventEmitter.emit("progressBar", false);
    throw error;
  }
);

const HTTP = {
  post: post,
  get: get,
  put: put,
  delete: deleteRequest,
  upload: upload,
};

export default HTTP;
