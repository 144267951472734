import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApiUrl;

export const globalUserControl = async (
  model,
  action = {
    users: [], // to signout 1 user, send array of 1 element.. with array of _ids' or array of objects with _id key...
    selectAll: false,
  }
) => {
  try {
    const url = baseApiUrl + model + "/globalUserControl"; // // By Default this API signsout all Users according to appModules param value

    const res = await HTTP.post(url, action);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const dynamicApiCall = async (url, method, body, additionalHeaders) => {
  try {
    const res = await HTTP[method](url, body, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const notifyUsers = async (model, body = {}) => {
  try {
    const url = baseApiUrl + model + "/notify";

    const res = await HTTP.post(url, body);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPaginatedData = async (
  model,
  { page, pageSize, sort, criteria, ...query } = {},
  additionalHeaders
) => {
  try {
    const url = baseApiUrl + model + "/paginate";

    const params = {
      ...query,
      pageNo: page + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// for actual pagination, use above function.. not below.. kuch bee it is..
export const paginate = async (model, criteria = {}, extras) => {
  try {
    const url = baseApiUrl + model + "/paginate";

    const res = await HTTP.post(url, { criteria, ...extras });
    return res?.data?.data?.records;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const paginateWithHook = async (
  model,
  { page, pageSize, sort, criteria, ...query } = {}
) => {
  try {
    const url = baseApiUrl + model + "/paginate?hook=true";

    const params = {
      ...query,
      pageNo: page + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const customPaginateWithHook = async (
  model,
  { page, pageSize, sort, criteria, ...query } = {}
) => {
  try {
    const url = baseApiUrl + "c/" + model + "/customPaginate?hook=true";

    const params = {
      ...query,
      pageNo: page + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const search = async (model, criteria = {}, additionalHeaders) => {
  try {
    const url = baseApiUrl + model + "/search";

    const res = await HTTP.post(url, criteria, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getByCode = async (model, code) => {
  try {
    const url = baseApiUrl + model + "/" + code;

    const res = await HTTP.get(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getSingleRecord = async (model, criteria = {}) => {
  try {
    const url = baseApiUrl + model + "/getSingleRecord";

    const res = await HTTP.post(url, criteria);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createRecord = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.post(baseApiUrl + model, data, additionalHeaders);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + model + "?hook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecord = async (model, data) => {
  try {
    const res = await HTTP.put(baseApiUrl + model + "/" + data?.code, data);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.put(
      baseApiUrl + model + "/" + data?.code + "?hook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateListRecords = async (model, data) => {
  try {
    const res = await HTTP.post(baseApiUrl + model + "/updateList", { data });

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateIAMRecord = async (
  model,
  data,
  { additionalHeaders } = {}
) => {
  try {
    const url = commonConfig.iamBaseURL + "iam/" + model + "/" + data?.code;

    const res = await HTTP.put(url, data, {
      additionalHeaders,
    });

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const softDeleteRecord = async (model, code, hardDelete) => {
  try {
    const url = baseApiUrl + model + "/" + code;
    const res = await HTTP.delete(url, { hardDelete });

    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const uploadFiles = async (model, files, params = {}) => {
  try {
    let url = commonConfig.microservices.upload + model + "/upload";

    if (params.additionalPath) {
      url += "?additionalPath=" + params.additionalPath;
    }

    if (params.additionalPath && params.code) {
      url += "&code=" + params.code;
    } else if (params.code) {
      url += "?code=" + params.code;
    }
    if ((params.additionalPath || params.code) && params.replace) {
      url += "&replace=true";
    } else if (params.replace) {
      url += "?replace=true";
    }

    const res = await HTTP.upload(url, files);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyUploadFiles = async (model, files, params = {}) => {
  try {
    // const res = await HTTP.post(url, formData, {
    //   "content-type": "multipart/form-data",
    // });
    let url = commonConfig.microservices.upload + model + "/upload?verify=true";
    if (params.additionalPath) {
      url += "&additionalPath=" + params.additionalPath;
    }
    const res = await HTTP.upload(url, files);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const uploadFilesWithHook = async (model, files, params = {}) => {
  try {
    let url = commonConfig.microservices.upload + model + "/upload?hook=true";
    if (params.additionalPath) {
      url += "&additionalPath=" + params.additionalPath;
    }

    const res = await HTTP.upload(url, files);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
