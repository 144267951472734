import { Grid } from "@material-ui/core/index";
import { isAdmin, isVendor, userDetails } from "@ui/Utils/helper";
import { useAuth } from "@ui/components/AuthProvider";
import commonConfig from "config/commonConfig";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DataDynamic from "../../assets/landingpage/Animations/Data/data-center-engineers.gif";
import OrderDynamic from "../../assets/landingpage/Animations/Orders/shopping-options.gif";
import DataIcon from "../../assets/landingpage/bar-content/data.svg";
import OrderStatic from "../../assets/landingpage/bar-content/order.svg";
import AccountBox from "./Account.component";
import { BottomData, RectangleDiv, TopBarData } from "./landing-page.style";

function LandingPage({ homePath }) {
  const auth = useAuth();
  const currentDomain = window.location.href?.toString().split("/");
  const [isHoveredIndex, setIsHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setIsHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setIsHoveredIndex(null);
  };

  const showHomePage =
    currentDomain?.length <= 4 ||
    currentDomain?.find((param) => param === "home");

  const navigate = useNavigate();

  const handleNavigation = (appModule) => {
    let url = `${commonConfig.hyperLinks[appModule.key]}#/`;
    const windowLocation = window.location.host?.toString().toLowerCase();

    switch (appModule.key) {
      case "data-engine":
        if (url.match("localhost")) url = "http://localhost:4000/#/";

        url += "data-engine/" + (isAdmin ? "inventory" : "dashboard");
        break;
      case "e-creatives":
        const path = isAdmin ? "orders" : "dashboard";
        if (windowLocation.match("orderpro-webdev")) {
          url = `https://${window.location.host}/#/e-creatives/${path}`;
        } else url += "e-creatives/" + path;

        break;
      case "cortex":
        url += "cortex/" + (isAdmin ? "training" : "training");
        break;

      case "vendor-portal":
        const vPath = isAdmin ? "quotations" : "dashboard";
        if (
          windowLocation.match("diva-dev") ||
          windowLocation.match("vendor-portal")
        ) {
          url = `https://${window.location.host}/#/vendor-portal/${vPath}`;
        } else if (windowLocation.match("localhost")) {
          url += "vendor-portal/" + vPath;
        } else {
          url = url.replace(
            "https://",
            `https://${
              userDetails?.client?.subDomain
                ? userDetails?.client?.subDomain + "-dot-"
                : ""
            }`
          );
        }

        break;
    }

    if (url?.match(commonConfig.appModule)) {
      window.location.href = url;
      window.location.reload();
    } else {
      window.open(url);
    }
  };

  const getModuleData = (key) => {
    let name = key;

    switch (key) {
      case "cortex":
        name = "Cortex";
        break;
      case "data-engine":
        name = "Data Engine";
        break;
      case "e-creatives":
        name = "Order Pro";
        break;

      case "vendor-portal":
        name = "Vendor Portal";
        break;
    }

    return {
      name,
      home: commonConfig.hyperLinks?.[key],
    };
  };

  const getIconWithName = (key) => {
    let Icons = {
      static: DataIcon,
      dynamic: DataDynamic,
    };

    switch (key) {
      case "data-engine":
        Icons = {
          static: DataIcon,
          dynamic: DataDynamic,
        };
        break;

      default:
        Icons = {
          static: OrderStatic,
          dynamic: OrderDynamic,
        };
        break;
    }

    return Icons;
  };

  const getColorWithName = (key) => {
    let color = "#FFEF99";

    switch (key) {
      case "cortex":
        color = "#EACCFF";
        break;
      case "data-engine":
        color = "#BFE0FF";
        break;
      case "e-creatives":
        color = "#C8EEC8";
        break;

      case "vendor-portal":
        color = "#FDDE9A";
        break;
    }

    return color;
  };

  const barData = auth?.user?.client?.appModules
    ?.map((module) => ({
      ...module,
      name: getModuleData(module?.key).name,
      icon: getIconWithName(module?.key),
      backgroundColor: getColorWithName(module?.key),
    }))
    .filter((appModule) => {
      if (isVendor) {
        return appModule.key === "vendor-portal";
      }

      if (userDetails._id) return true;

      return false;
    });

  if (!auth?.user) {
    return <Navigate to="/signIn" />;
  }
  if (showHomePage && commonConfig.appModule !== "iam") {
    return (
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <AccountBox
            fullName={{
              ...userDetails,
            }}
          />
        </Grid>

        <Grid item xs={12} container spacing={4}>
          {barData?.map((data, index) =>
            Object.keys(commonConfig.hyperLinks || {}).find(
              (module) => module === data?.key
            ) ? (
              <Grid item xs={3} key={uniqueId()}>
                <RectangleDiv
                  backgroundColor={data?.backgroundColor}
                  onClick={() => handleNavigation(data)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <TopBarData>
                    <img
                      className="top_bar_content static"
                      src={
                        isHoveredIndex === index
                          ? data?.icon?.dynamic
                          : data?.icon?.static
                      }
                      alt="top-bar-icon"
                      data-testid="top-bar-content"
                      width={180}
                    />
                  </TopBarData>
                  <BottomData data-testid="bottom-bar-content">
                    {data?.name}
                  </BottomData>
                </RectangleDiv>
              </Grid>
            ) : null
          )}
        </Grid>
      </Grid>
    );
  }
  return <Navigate to={homePath} />;
}

export default LandingPage;
