import React from "react";

// Components
import HomePage from "pages/HomePage/HomePage";

// utils
import enums from "helpers/enums";
import reportsIcon from "./assets/menuIcons/Reports.png";
import { isAdmin, isDivisionControlled, isSuperAdmin } from "@ui/Utils/helper";
import UsersList from "views/Users/List/List";
import UserDetail from "views/Users/Detail/Detail";
import TrainingDetail from "views/Training/Detail/Detail";
import IdentifyDetail from "views/Identify/Detail/Detail";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import CatalogDetail from "views/Catalog/Detail/Detail";

const styles = {
  icon: { height: 30, width: 30 },
};

const MenuIcon = ({ icon }) => {
  return <img src={icon} alt="ico" style={styles.icon} />;
};

export const primaryMenuRoutes = [
  {
    access: true,
    title: "Train",
    path: enums.models.training,
    icon: () => <ModelTrainingIcon style={{ color: "grey" }} />,
    component: () => <TrainingDetail />,
  },
  {
    access: true,
    title: "Identify",
    path: enums.models.identify,
    icon: () => <FingerprintIcon style={{ color: "grey" }} />,
    component: () => <IdentifyDetail />,
  },
  {
    access: true,
    title: "Catalog",
    path: enums.models.candidateImages,
    icon: () => <FingerprintIcon style={{ color: "grey" }} />,
    component: () => <CatalogDetail />,
  },
  // {
  //   access: true,
  //   title: "Users",
  //   path: enums.models.users,
  //   icon: () => <MenuIcon icon={reportsIcon} />,
  //   component: () => <UsersList />,
  // },
];

export const secondaryMenuRoutes = [
  // {
  //   access: true,
  //   title: "Users",
  //   path: enums.models.users + "/:code",
  //   component: () => <UserDetail />,
  // },
];
