import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Breadcrumbs, Link, Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import {
  MuiFab,
  MuiHidden,
  MuiIconButton,
  MuiArrowBack,
} from "../assets/materialUI";
import { colorPalette, commonStyle } from "../assets";
import { useNavigate } from "react-router-dom";
import { defaultGoldHex, defaultLightGoldHex } from "@ui/Utils/helper";

const useStyles = makeStyles((theme) => ({
  rootElement: {
    padding: "0px 1px 16px 1px",
  },
  hideElement: {
    display: "none",
  },
  addSecondaryContactContainer: {
    ...commonStyle.saveIcon,
    width: 34,
    height: 34,
  },
  captionStyle: {
    color: colorPalette.black,
  },
  iconContainer: {
    marginRight: 0,
  },
  setHeaderText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "80%",
    fontWeight: "bold",
  },
  setHeaderTextFull: {
    overflow: "hidden",
    width: "100%",
    fontWeight: "bold",
  },
  noMaxWidth: {
    maxWidth: "none",
  },
  actionButton: {
    minWidth: "100px",
    maxHeight: "33px",
    fontWeight: 900,
    letterSpacing: 1.75,
    boxShadow: "none",
    fontSize: "14px",
  },
}));

const collapseLabel = (name, collapseLinks) => {
  if (collapseLinks) {
    return <div>...</div>;
  } else {
    return <div>{name}</div>;
  }
};

const Header = (props) => {
  const classes = useStyles();
  const {
    pageTitle,
    links,
    maxItems,
    collapseLinks,
    headerMdSpace,
    actionMdSpace,
    showTooltip,
    collapseTitle,
    onClickOfBackButton = null,
  } = props;
  const noOfLinks = links ? links.length : 0;
  const navigate = useNavigate();

  const breadcombDetails = (link, index) => {
    if (link && link.isDisplayLink) {
      return (
        <Link
          key={index}
          variant="body2"
          style={{ fontWeight: 600, color: "#adadad" }}
          onClick={(e) => {
            let url = link.url;
            e.preventDefault();
            //here we pass custom function to navigate another module
            if (link.onClick && typeof link.onClick === "function") {
              url = link.onClick();
            } else {
              let url = link.url;
              if (url[0] === "#") {
                url = link.url.replace("#", "");
              }
              navigate(url);
            }
          }}
        >
          {collapseLinks
            ? link.name
            : noOfLinks > 2
            ? collapseLabel(link.name)
            : link.name}
        </Link>
      );
    } else {
      return (
        <Typography
          key={index}
          component="div"
          variant="body2"
          style={{ fontWeight: 600, color: defaultLightGoldHex }}
        >
          {collapseLinks
            ? noOfLinks - 1 === index
              ? link.name
              : collapseLabel(link.name, collapseLinks)
            : noOfLinks > 2
            ? collapseLabel(link.name)
            : link.name}
        </Typography>
      );
    }
  };

  const renderActions = () => {
    if (props.actions) {
      return props.actions.map((action, index) => (
        <Action
          action={action}
          key={"action-" + index}
          disabled={action.disabled}
        />
      ));
    }
    return null;
  };

  return (
    <Grid
      id="sfsFormHeader"
      alignItems="center"
      justifyContent="space-between"
      container
      className={classes.rootElement}
      style={props.style}
    >
      <Grid item xs={7} md={headerMdSpace ? headerMdSpace : 6}>
        <Grid item>
          <Grid container direction="row">
            {!!onClickOfBackButton && (
              <MuiIconButton
                onClick={onClickOfBackButton}
                style={{ padding: "0px", paddingRight: "16px" }}
              >
                <MuiArrowBack />
              </MuiIconButton>
            )}
            <Tooltip
              classes={{ tooltip: classes.noMaxWidth }}
              disableFocusListener={!showTooltip}
              disableHoverListener={!showTooltip}
              disableTouchListener={!showTooltip}
              title={pageTitle}
              placement="bottom"
            >
              <Typography
                gutterBottom
                variant="h3"
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  color: defaultGoldHex,
                }}
                className={
                  collapseTitle
                    ? classes.setHeaderText
                    : classes.setHeaderTextFull
                }
              >
                {pageTitle}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item style={{ top: "-2px", position: "relative" }}>
          <Breadcrumbs aria-label="breadcrumb" maxItems={maxItems}>
            {links.map(breadcombDetails)}
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid
        item
        xs={5}
        md={actionMdSpace ? actionMdSpace : 6}
        container
        justifyContent="flex-end"
        wrap="nowrap"
        spacing={1}
        style={{ alignSelf: "center" }}
      >
        {renderActions()}
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  titleofDcoument: PropTypes.string,
  links: PropTypes.array,
  onCreate: PropTypes.func,
  isCreateEnabled: PropTypes.bool,
};

const Action = (props) => {
  const classes = useStyles();
  const { action } = props;
  const handleOnClick = (event) => {
    if (action.onClick) {
      action.onClick(event);
      event.stopPropagation();
    }
  };
  const classNames = action.hidden ? classes.hideElement : "";
  const displayComponent = () => {
    const button = (
      <React.Fragment>
        <MuiHidden lgUp>
          <MuiFab
            id={action.id || "sfs_action_btn"}
            size="small"
            onClick={handleOnClick}
            disableRipple={true}
            disableFocusRipple={true}
            color={
              action.disabled
                ? action.color || "blueGrey"
                : action.color || "secondary"
            }
            type={action.type}
            disabled={action.disabled}
            hidden={action.hidden}
          >
            <Icon>
              {action.iconSvg ? (
                <img
                  src={action.iconSvg}
                  alt={"Icon"}
                  style={{
                    ...action.iconSvgStyle,
                  }}
                />
              ) : (
                action.iconName
              )}
            </Icon>
          </MuiFab>
        </MuiHidden>

        <MuiHidden mdDown>
          {action?.iconName === "cancel" ? (
            <Button
              id={action.id || "sfs_action_btn"}
              //color="secondary"
              variant={action.variant || "contained"}
              style={
                !action.disabled
                  ? action.style || {
                      backgroundColor: "white",
                      color: "#ffa41c",
                      border: "1px solid #ffa41c",
                    }
                  : {}
              }
              color={
                action.disabled
                  ? action.color || "blueGrey"
                  : action.color || "secondary"
              }
              type={action.type}
              onClick={handleOnClick}
              className={classes.actionButton}
              disabled={action.disabled}
            >
              {action.label}
            </Button>
          ) : (
            <>
              <Button
                id={action.id || "sfs_action_btn"}
                variant={action.variant || "contained"}
                type={action.type}
                onClick={handleOnClick}
                color={action.color || "secondary"}
                style={action.style || { boxShadow: "none" }}
                className={classes.actionButton}
                disabled={action.disabled}
              >
                {action.label}
              </Button>
            </>
          )}
        </MuiHidden>
      </React.Fragment>
    );

    if (action.tooltip) {
      // fix for issue #1049
      // https://github.com/mbrn/material-table/issues/1049

      return (
        <Tooltip title={action.tooltip}>
          <span>{button}</span>
        </Tooltip>
      );
    } else {
      return button;
    }
  };

  return (
    <Grid item className={classNames}>
      {displayComponent()}
    </Grid>
  );
};

Header.defaultProps = { showTitle: false, collapseTitle: true };

export default Header;
