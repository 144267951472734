import { Grid } from "@material-ui/core";
import Label from "@ui/components/Label";
import { globalUserControl } from "@ui/ComponentUtils/blueprintAPIs";
import { globalDateFormat, RHCheckbox } from "@ui/ReactHookFormControls/index";
import RHAsyncAutoComplete from "@ui/ReactHookFormControls/RHAsyncAutoComplete";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import RHDocUpload from "@ui/ReactHookFormControls/RHDocUpload";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import commonConfig from "config/commonConfig";
import ExcelJS from "exceljs";
import { isUndefined } from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import Resizer from "react-image-file-resizer";
import enums from "../../helpers/enums";
import {
  alphabets,
  downloadExcelFile,
  excelAlignment,
  excelFont,
  getCellStyle,
  sheetNames,
} from "./excelImportHelpers";
import HTTP from "./http";

const base64toFile = (base64, filename) => {
  let arr = base64.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const resizeFile = (file, type) => {
  // console.log(Resizer);
  // if (!Resizer?.imageFileResizer) {
  //   return file;
  // }

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      100,
      0,
      (uri) => {
        if (type === "base64") resolve(uri);
        const resizedImage = base64toFile(uri, file.name);
        resolve(resizedImage);
      },
      "base64"
    );
  });

  // Resizer.imageFileResizer(
  //   file, // Is the file of the image which will resized.
  //   maxWidth, // Is the maxWidth of the resized new image.
  //   maxHeight, // Is the maxHeight of the resized new image.
  //   compressFormat, // Is the compressFormat of the resized new image.
  //   quality, // Is the quality of the resized new image.
  //   rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  //   responseUriFunc, // Is the callBack function of the resized new image URI.
  //   outputType, // Is the output type of the resized new image.
  //   minWidth, // Is the minWidth of the resized new image.
  //   minHeight // Is the minHeight of the resized new image.
  // );
};

const downloadFile = (item) => {
  try {
    const a = document.createElement("a");
    a.href = item?.location;
    a.download = `${item?.fileName}.${item?.fileType?.split("/")[1]}`;
    a.target = "hiddenIframe";
    a.click();
  } catch (error) {
    console.log(error);
  }
};

const getNestedObjValue = (obj, nestedKey) => {
  if (obj && nestedKey) {
    const keys = nestedKey?.toString().split(".") || [];
    let value = { ...obj };

    keys.map((key) => (value = value?.[key] || ""));

    return value;
  }
  return "";
};

export const fetchLocationName = async (lat, lng) => {
  try {
    let response = await HTTP.get(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`
    );

    return `${response?.data?.locality}, ${response?.data?.city}, ${response?.data?.countryName}`;
  } catch (err) {
    console.log(err);
    return "";
  }
};

const getRHFieldComponent = (field, { isEditable, disableFields, values }) => {
  switch (field.fieldType) {
    case "upload":
      return field.hidden ? null : (
        <>
          <RHDocUpload
            required={field.required}
            onVerify={field.onVerify}
            code={field.code}
            previewThumbnail={field.previewThumbnail}
            single={field.single}
            isEditable={isEditable}
            model={field.model}
            name={field.name}
            label={field.label}
            additionalPath={field.additionalPath}
            dataEngine={field.dataEngine}
            replace={field.replace}
            accept={field.accept || "*"}
            multiple={field.multiple}
            onChange={field.onChange || (() => {})}
            disabled={!isEditable || disableFields}
          />
        </>
      );

    case "textField":
      let value = field.render
        ? getNestedObjValue(values, field.render)
        : getNestedObjValue(values, field.name);

      if (field.hash) {
        value = "**********";
      }

      return field.hidden ? null : !isEditable || field.readOnly ? (
        <Label
          value={value}
          label={field.label}
          valueStyle={field.valueStyle}
        />
      ) : (
        <RHTextField
          name={field.name}
          label={field.label}
          type={field?.dataType}
          onChange={field.onChange || (() => {})}
          disabled={disableFields}
          multiline={field.multiline}
          rows={field.rows}
          placeholder={field.placeholder}
          style={field.style}
          onKeyDown={field.onKeyDown}
        />
      );

    case "dropdown":
      return field.hidden ? null : !isEditable || field.readOnly ? (
        <Label
          value={
            field.multiple
              ? (values?.[field.name]?.map
                  ? values?.[field.name]
                  : [values?.[field.name]]
                )
                  ?.map((ele) => {
                    return field.render === "firstName"
                      ? (ele?.firstName || "") + " " + (ele?.lastName || "")
                      : field.render
                      ? ele?.[field.render]
                      : ele;
                  })
                  ?.join(", ")
              : typeof field.render === "function"
              ? field.render()
              : field.render
              ? getNestedObjValue(values, field.render)
              : values?.[field.name]
          }
          label={field.label}
        />
      ) : (
        <RHAutoComplete
          selectOnFocus={field.selectOnFocus}
          clearOnBlur={field.clearOnBlur}
          freeSolo={field.freeSolo}
          filterOptions={field.filterOptions}
          disableClearable={field.disableClearable}
          extraOptions={field.extraOptions}
          groupBy={field.groupBy}
          renderFunc={field.renderFunc}
          required={field.required}
          defaultValue={field.defaultValue}
          multiple={field.multiple}
          name={field.name}
          label={field.label}
          onChange={field.onChange || (() => {})}
          options={field.options || []}
          getOptionLabel={field.getOptionLabel}
          getOptionSelected={(option, value) =>
            field.getOptionSelected && field.getOptionSelected(option, value)
          }
          render={field.render}
          renderOption={field.renderOption}
          disabled={disableFields}
        />
      );

    case "asyncDropdown":
      return field.hidden ? null : isEditable || disableFields ? (
        <RHAsyncAutoComplete
          selectOnFocus={field.selectOnFocus}
          clearOnBlur={field.clearOnBlur}
          freeSolo={field.freeSolo}
          filterOptions={field.filterOptions}
          disableClearable={field.disableClearable}
          extraOptions={field.extraOptions}
          groupBy={field.groupBy}
          renderFunc={field.renderFunc}
          required={field.required}
          defaultValue={field.defaultValue}
          multiple={field.multiple}
          name={field.name}
          label={field.label}
          onChange={field.onChange || (() => {})}
          options={field.options || []}
          getOptionLabel={field.getOptionLabel}
          getOptionSelected={(option, value) =>
            field.getOptionSelected && field.getOptionSelected(option, value)
          }
          render={field.render}
          renderOption={field.renderOption}
          disabled={disableFields}
          apiUrl={field.apiUrl}
          apiMethod={field.apiMethod}
          apiBody={field.apiBody}
          apiSelect={field.apiSelect}
        />
      ) : (
        <Label
          value={
            field.multiple
              ? values?.[field.name]
                  ?.map((ele) => {
                    return field.render === "firstName"
                      ? (ele?.firstName || "") + " " + (ele?.lastName || "")
                      : field.render
                      ? ele?.[field.render]
                      : ele;
                  })
                  ?.join(", ")
              : typeof field.render === "function"
              ? field.render()
              : field.render
              ? getNestedObjValue(values, field.render)
              : values?.[field.name]
          }
          label={field.label}
        />
      );

    case "featureCheckbox":
      return field.hidden ? null : (
        <>
          <Label label={field.label} noColon />

          <Grid container spacing={2}>
            {field.features?.map((feature, featureIndex) =>
              feature.hidden ? null : (
                <Fragment key={featureIndex}>
                  <Grid item xs={1}>
                    <RHCheckbox
                      disabled={feature.disabled || !isEditable}
                      name={field.name + "." + feature.name}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Label label={feature.label} noColon />
                  </Grid>
                </Fragment>
              )
            )}
          </Grid>
        </>
      );

    default:
      return field.hidden ? null : (
        <>
          <Label
            value={values?.[field.name] || field.value}
            label={field.label}
          />
          {field.copyToClipboard && (
            <button
              onClick={() => {
                var text = values?.[field.name] || field.value || "";
                navigator.clipboard.writeText(text).then(
                  function () {
                    console.log("Async: Copying to clipboard was successful!");
                    CustomEventEmitter.emit(
                      "alert_success",
                      "Copied to clipboard!"
                    );
                  },
                  function (err) {
                    console.error("Async: Could not copy text: ", err);
                  }
                );
              }}
            >
              Copy to clipboard
            </button>
          )}
        </>
      );
  }
};

let obj =
  (window.localStorage.getItem("userDetails") === "undefined"
    ? "{}"
    : window.localStorage.getItem("userDetails")) || "{}";
obj = obj ? JSON.parse(obj) : {};

const userDetails = {
  ...obj,
  fullName: (obj?.firstName || "") + " " + (obj?.lastName || ""),
  client: {
    ...obj?.client,
    premiumClient: {
      ...obj?.client?.premiumClient,
      unlimitedBucketStorage:
        obj?.client?.premiumClient?.unlimitedBucketStorage,
    },
  },
};

export const isDivisionControlled =
  userDetails?.client?.premiumClient?.divisionControl;

export const userDivision = userDetails?.division;

const isSuperAdmin = userDetails?.role === "Super Admin";

const isAdmin = userDetails?.role === enums.roles.admin;

export const isCustomer = userDetails?.role === enums.roles.customer;

export const isVendor = userDetails?.role === enums.roles.vendor;

// ref: https://stackoverflow.com/questions/39997067/es6-unique-array-of-objects-with-set
export const uniqueArray = (a = []) => {
  return [...new Set(a.map((o) => JSON.stringify(o)))].map((s) =>
    JSON.parse(s)
  );
};

export const formatDateToRead = (date, format = globalDateFormat.read) => {
  return moment(date).format(format);
};

export const generatePDF = async (pdfData, fileNameWithoutExt) => {
  try {
    const linkSource = `data:application/pdf;base64,${pdfData}`;

    const downloadLink = document.createElement("a");
    const fileName = `${fileNameWithoutExt}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    CustomEventEmitter.emit("alert_success", "Download Successfull");
  } catch (err) {
    CustomEventEmitter.emit("alert_error", "Something went wrong");
  }
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );

  CustomEventEmitter.emit("alert_success", "Copied to clipboard");
};

// CHECK IF IMAGE EXISTS
export const checkIfImageExists = (url) => {
  return new Promise(function (resolve) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      resolve(true);
    } else {
      img.onload = () => {
        resolve(true);
      };

      img.onerror = () => {
        resolve(false);
      };
    }
  });
};
const division = window.localStorage.getItem("division");

export const selectedDivisionId = division
  ? isUndefined(division) || division === "undefined" || division === "null"
    ? {}
    : JSON.parse(window.localStorage.getItem("division"))?._id
  : "";
export const selectedDivisionName = window.localStorage.getItem("division")
  ? isUndefined(division) || division === "undefined" || division === "null"
    ? {}
    : JSON.parse(window.localStorage.getItem("division"))?.name
  : "";

export const getCortexImageUri = ({
  fileName,
  imageFlag,
  client,
  division,
  model = "training",
} = {}) =>
  imageFlag
    ? `https://storage.googleapis.com/${commonConfig.moduleBuckets?.cortex}/${
        enums.keywords.cortex
      }/${userDetails?.client?._id || client?._id || client || "clientLess"}/${
        isDivisionControlled || division?._id
          ? `${division?._id || selectedDivisionId}/`
          : ""
      }${model}/${fileName}`
    : "https://storage.googleapis.com/sheen-upload/Assets/Common/oopsNotFound.png";

export const getProductImageUri = ({
  styleNo,
  imageFlag,
  client,
  division,
} = {}) =>
  imageFlag
    ? `https://storage.googleapis.com/${commonConfig.dataEngineBucketName}/${
        enums.keywords.dataEngine
      }/${userDetails?.client?._id || client?._id || client || "clientLess"}/${
        isDivisionControlled || division?._id
          ? `${division?._id || selectedDivisionId}/`
          : ""
      }products/${styleNo}.png`
    : "https://storage.googleapis.com/sheen-upload/Assets/Common/oopsNotFound.png";

export const getDesignImageUri = (styleNo) =>
  `https://storage.googleapis.com/${commonConfig.dataEngineBucketName}/${
    enums.keywords.dataEngine
  }/${userDetails?.client?._id || "clientLess"}/${
    isDivisionControlled ? `${selectedDivisionId}/` : ""
  }products/${styleNo}.png`;

export const getValidDesignImageUri = async (
  styleNo,
  { imageNotFound, oopsNotFound } = {}
) => {
  const location = `https://storage.googleapis.com/${
    commonConfig.dataEngineBucketName
  }/${enums.keywords.dataEngine}/${userDetails?.client?._id || "clientLess"}/${
    isDivisionControlled ? `${selectedDivisionId}/` : ""
  }products/${styleNo}.png`;

  const exist = await checkIfImageExists(location);

  let altLoc =
    "https://storage.googleapis.com/sheen-upload/Assets/Common/oopsNotFound.png";

  if (imageNotFound) {
    altLoc =
      "https://storage.googleapis.com/sheen-upload/Assets/Common/image-not-found.jpg";
  } else if (oopsNotFound) {
    altLoc =
      "https://storage.googleapis.com/sheen-upload/Assets/Common/oopsNotFound.png";
  }

  return exist ? location : altLoc;
};

export const defaultGoldHex = "#cc9e04";
export const defaultLightGoldHex = "#fcb823";
export const defaultLighterGoldHex = "#ffd578";
export const defaultLightestGoldHex = "#ffe09c";

export const downloadDGXL = function (e) {
  try {
    e.grid.downloadDataAsCSV();
  } catch (err) {
    console.log(err);
  }
};

export const regexPurify = (element) => {
  return element.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const roundOff = (value, { weight } = {}) => {
  if (weight) {
    return Number(value).toFixed(3);
  }

  return Number(value).toFixed(2);
};

export const getLowestOrderStatus = (items) => {
  let availableStats = (items || [])?.map((item) => item?.orderStatus);
  availableStats = uniqueArray(availableStats);
  const statusEnums = Object.values(enums.orderStatus); // if you get an error in this line, just add orderStatus key in your web/enums.js file
  let lowest = statusEnums.indexOf(availableStats[0]);

  availableStats.map((status, statusIndex) => {
    let currentStatusRank = statusEnums.indexOf(status);
    if (currentStatusRank < lowest) {
      lowest = statusIndex;
    }
  });

  return statusEnums[lowest];
};

export {
  base64toFile,
  downloadFile,
  getNestedObjValue,
  getRHFieldComponent,
  isAdmin,
  isSuperAdmin,
  resizeFile,
  userDetails,
};

// #Less Goooooooo...

export const handleGlobalUserControl = async (
  // By Default this function signsout all Users according to appModules param value
  action = { selectAll: true, users: [] },
  // hidden iamControl.. Dont set default values for iamControl Object..
  // till now; const { disabled } = iamControl.,
  appModules = [] // array of appModule keys.. ["data-engine", "e-creatives"]
) => {
  try {
    let message = { future: "Signout ", past: "Signed out " };
    if (action.iamControl) {
      if (action.iamControl.disabled)
        message = { future: "Disable", past: "Disabled " };
      else message = { future: "Approve ", past: "Approved " };
    }

    if (
      window.confirm(
        `Are you sure to Globally ${message.future} ${
          action.selectAll
            ? `ALL Users in [${appModules.join(", ")}] Projects?`
            : `[${(action.users || [])
                .map((user) => user?.firstName + " " + user?.lastName)
                .join(", ")}] ?`
        }`
      )
    ) {
      let modifiedUsersCount = 0;

      await Promise.all(
        appModules.map(async (appKey) => {
          const res = await globalUserControl(`${appKey}/users`, action);
          modifiedUsersCount += Number(res.nModified);
        })
      );

      CustomEventEmitter.emit(
        "alert_success",
        `${message.past} ${modifiedUsersCount}  Users successfully`
      );
    }
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit(
      "alert_error",
      err?.response?.data?.message || "Something went wrong..."
    );
    throw err;
  }
};

export const userAccessMenus = (routes) => {
  routes = routes.filter((route) => {
    if (route.path?.toString().toLowerCase().match("dashboard")) {
      return true;
    }

    return isAdmin || isSuperAdmin;
  });
};

// remove below function ASAP
export const userHasAccess = () => {
  const location = window.location.href;
  const appModule = location.split("/")[4],
    entityName = location.split("/")[5];
  const isDashboard = location.toLowerCase().match("dashboard");

  // switch (appModule) {
  //   case "e-creatives":
  //     const screenAccess = isDashboard ? true : isAdmin || isSuperAdmin;

  //     return {
  //       screen: screenAccess,
  //     };
  //   case "data-engine":
  //     const screen = isDashboard ? true : isAdmin || isSuperAdmin;

  //     return {
  //       screen,
  //     };

  //   case "vendor-portal":
  //     const access =

  //     return {
  //       screen: access,
  //     };
  // }

  return { screen: isDashboard ? true : isAdmin || isSuperAdmin };
};

export const delay = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const connectPrinter = async () => {
  try {
    const device = await navigator.usb.requestDevice({ filters: [] });
    // Handle the connected printer device
    console.log("Connected device:", device);

    return device;
  } catch (error) {
    console.error("Error connecting to printer:", error);
    throw error;
  }
};

export const printFromConnectedDevice = async (
  device,
  command,
  // [
  //   "SIZE 48 mm,25 mm",
  //   "CLS",
  //   'TEXT 30,10,"4",0,1,1,"HackerNoon"',
  //   'TEXT 30,50,"2",0,1,1,"WebUSB API"',
  //   'BARCODE 30,80,"128",70,1,0,2,2,"altospos.com"',
  //   "PRINT 1",
  //   "END",
  // ];
  { refresh } = {}
) => {
  try {
    if (device?.open && command) {
      const cmds = command;
      await device.open();
      await device.selectConfiguration(1);
      await device.claimInterface(0);
      await device.transferOut(
        device.configuration.interfaces[0].alternate.endpoints.find(
          (obj) => obj.direction === "out"
        ).endpointNumber,
        new Uint8Array(new TextEncoder().encode(cmds.join("\r\n")))
      );
      await device.close();

      return;
    } else {
      CustomEventEmitter.emit(
        "alert_error",
        `${
          !device?.open
            ? "Connect printer to continue"
            : "Print command should not be empty"
        }`
      );
    }
    throw {
      message: `${
        !device?.open
          ? "Connect printer to continue"
          : "Print command should not be empty"
      }`,
    };
  } catch (err) {
    console.log("Error while printing", err);
    throw err;
  }
};

// for material table usage in options prop:
/* exportButton: {
  pdf: true,
  csv: true
},
exportCsv: (data, columns) => exportExcel([{data, columns, sheetName: "Stocks"}], "Stocks"),
*/

export const exportExcel = async (
  pages = [
    {
      data: [
        {
          // [name]: value
        },
      ],
      columns: [{ title: "", field: "", width: 20 }],
      sheetName: "Data",
    },
  ],
  fileName = "Report: " + formatDateToRead(new Date())
) => {
  try {
    const defaultRowHeight = {
      header: 25,
    };
    const workbook = new ExcelJS.Workbook();

    for (let i = 0; i < pages.length; i++) {
      const currentPage = pages[i];
      const data = currentPage.data;
      const columns = currentPage.columns;

      const currentSheet = workbook.addWorksheet(
        currentPage.sheetName || `sheet${i + 1}`
      );

      currentSheet.getRow("1").height = defaultRowHeight.header;
      currentSheet.columns = columns.map((col) => ({
        header: col.title,
        width: col.width,
      }));

      // Design columns
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        // Styling only HEADERS
        currentSheet.getCell(`${alphabets[colIndex] + 1}`).style = getCellStyle(
          excelFont.size.headers,
          excelFont.color.headers,
          excelFont.bold.headers
        );

        currentSheet.getColumn(`${alphabets[colIndex]}`).alignment = {
          wrapText: excelAlignment.body.wrap,
        };

        currentSheet.getCell(`'${alphabets[colIndex] + 1}'`).alignment = {
          vertical: excelAlignment.headers.vertical,
          horizontal: excelAlignment.headers.horizontal,
          wrapText: true,
        };
      }

      let excelRow = 2;

      data.map((row) => {
        columns.map((col, colIndex) => {
          if (currentPage.sheetName === sheetNames.diamondDetails) {
            let rowValue = "";

            if (col.field === "sku" && row.diamondDetails?.length) {
              rowValue = row.sku;
            } else {
              (row.diamondDetails || []).find((diamondRow) => {
                const matchedDiamondCol = Object.keys(diamondRow).find(
                  (key) => key === col.field
                );
                if (matchedDiamondCol) {
                  rowValue = diamondRow[matchedDiamondCol];
                  return true;
                }
              });
            }

            currentSheet.getCell(alphabets[colIndex] + excelRow).value =
              rowValue;
          } else {
            currentSheet.getCell(alphabets[colIndex] + excelRow).value =
              getNestedObjValue(row, col.field);
          }
        });

        excelRow++;
      });
    }

    downloadExcelFile(workbook, fileName);
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Something went wrong!");
  }
};

// const dataUrlToFile = async (dataUrl, fileName, mimeType) => {
//   // console.log({ dataUrl, fileName, mimeType });
//   const res = await axios(dataUrl);
//   const blob = res.data;
//   return new File([blob], fileName, { type: mimeType });
// };
