import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { withStyles } from "@mui/styles";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// Usage example
// {changePasswordDialog && (
//   <Dialog open={changePasswordDialog}>
//     <MyDialogTitle onClose={() => setChangePasswordDialog(false)}>
//       Change Password
//     </MyDialogTitle>
//     <MyDialogContent>
//       <TextField></TextField>
//     </MyDialogContent>
//   </Dialog>
// )}

const styles = (theme) => ({
  root: {
    margin: 0,
    marginBottom: "8px",
    padding: 2,
  },
  closeButton: {
    color: "grey",
    cursor: "pointer",
  },
  editButton: {
    color: "grey",
    cursor: "pointer",
  },
});

const MyDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, onEdit, setPdfDialog, ...other } = props;
  const [cancelHover, setCancelHover] = useState(false);
  const [editHover, setEditHover] = useState(false);

  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Grid container spacing={3} style={{ margin: "8px" }}>
        <Grid item xs={10}>
          <Typography
            style={{ color: "gold", fontSize: 14, fontWeight: "bold" }}
          >
            {children}
          </Typography>
        </Grid>
        {onEdit ? (
          <Grid item xs={1}>
            <IconButton
              aria-label="edit"
              id="edit"
              onMouseEnter={() => setEditHover(true)}
              onMouseLeave={() => setEditHover(false)}
              className={classes.editButton}
              onClick={onEdit}
            >
              <Tooltip title={"Edit"}>
                {editHover ? <EditIcon /> : <EditOutlinedIcon />}
              </Tooltip>
            </IconButton>
          </Grid>
        ) : (
          ""
        )}

        {onClose ? (
          <Grid item xs={1}>
            <IconButton
              id="close"
              className={classes.closeButton}
              onMouseEnter={() => setCancelHover(true)}
              onMouseLeave={() => setCancelHover(false)}
              onClick={() => onClose(false)}
            >
              <Tooltip title={"Cancel"}>
                {cancelHover ? <CancelIcon /> : <CancelOutlinedIcon />}
              </Tooltip>
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </DialogTitle>
  );
});

const MyDialogContent = withStyles((theme) => ({
  root: {
    padding: 2,
    margin: "8px",
  },
}))(DialogContent);

export { MyDialogTitle, MyDialogContent };
