import axios from "axios";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { resizeFile } from "./helper";
import { requestHeaders } from "./http";
const mAxios = axios.create();

const get = async (url, data, additionalHeaders) => {
  try {
    const headers = requestHeaders(additionalHeaders);

    const res = await mAxios.get(url, { headers });
    return res;
  } catch (err) {
    if (err.response?.status === 403) {
      window.localStorage.clear();
      window.location.reload();
    }

    console.log(err?.response?.data?.message);
    throw err;
  }
};

const post = async (url, data, additionalHeaders) => {
  try {
    const headers = requestHeaders(additionalHeaders);

    const res = await mAxios.post(url, data, { headers });
    return res;
  } catch (err) {
    if (err.response?.status === 403) {
      window.localStorage.clear();
      window.location.reload();
    }
    console.log(err?.response?.data?.message);

    throw err;
  }
};

export const put = async (url, data, { additionalHeaders } = {}) => {
  try {
    const headers = requestHeaders(additionalHeaders);

    const response = await mAxios.put(url, data, { headers });
    return response;
  } catch (error) {
    console.log("Error in put method of HTTP" + error?.response?.data?.message);
    throw error;
  }
};

export const deleteRequest = async (url, data) => {
  try {
    const headers = requestHeaders();
    const response = await mAxios.delete(url, { headers, data });
    return response;
  } catch (error) {
    console.log(
      "Error in deleteRequest method of HTTP" + error?.response?.data?.message
    );
    throw error;
  }
};

const upload = async (url, files, resize = true, overRideForm) => {
  let response = {},
    headers = requestHeaders(headers);
  if (overRideForm) {
    response = await axios.post(url, overRideForm, {
      headers,
    });
  } else {
    let formData = new FormData();
    formData.append("files", files);

    try {
      for (let i = 0; i < files.length; i++) {
        if (files[i]?.type?.includes("image")) {
          const imageFile = resize ? await resizeFile(files[i]) : files[i];
          formData.append("files", imageFile);
        } else {
          formData.append("files", files[i]);
        }
      }

      response = await mAxios.post(url, formData, {
        headers,
      });
    } catch (err) {
      console.log(
        "Error in upload method of HTTP" + err?.response?.data?.message
      );
      throw err;
    }
  }

  return response;
};

axios.interceptors.request.use(
  (config) => {
    // check for valid session / refreshed Token to current session.
    return config;
  },
  (error) => {
    console.error(error.message);
    throw error;
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(error.message);
    if (error.response?.status == 403) {
      CustomEventEmitter.emit(
        "alert_error",
        "Session expired. Login to continue..."
      );

      window.localStorage.clear();
      window.location.reload();
    }

    throw error;
  }
);

const HTTPWithoutLoading = {
  post: post,
  get: get,
  put: put,
  delete: deleteRequest,
  upload: upload,
};

export default HTTPWithoutLoading;
