import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import MuiProgressLine from "@ui/components/MuiProgressLineWithLoader";
import _, { filter } from "lodash";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import enums from "helpers/enums";
import { Grid, Typography, Button } from "@material-ui/core";
import DocUpload from "@ui/Controls/Upload/DocUpload";
import {
  appendFileNames,
  chunkImagesUpload,
  deleteCortexCache,
  getCortexImages,
  pushToCortex,
  uploadToMyCatalog,
  uploadTrainingImages,
} from "../api";
import ImageUpProgress from "./ImageUploadProgress";

import { getSingleRecord, search } from "@ui/ComponentUtils/blueprintAPIs";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import SaveReminder from "./SaveReminder";

const entitySchema = {};

const TrainingDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const [files, setFiles] = useState([]);
  const [folder, setFolder] = useState([]);
  const [display, setDisplay] = useState({
    report: {
      invalid: [],
      large: [],
      duplicates: [],
      failed: [],
      uploaded: [],
      cortexVectors: [],
    },
    total: 0,
  });
  const [trainedDoc, setTrainedDoc] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const res = (await getSingleRecord(enums.models.candidateImages)) || {};

        setTrainedDoc(res);
        setDisplay({
          ...display,
          report: { ...display.report, cortexVectors: res?.fileNames || [] },
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleImagesUpload = async (files) => {
    const reportObj = {
        invalid: [],
        large: [],
        duplicates: [],
        failed: [],
        uploaded: [],
      },
      payloadLimit = 5; // in mb.
    // lastModified: 1681473142860
    // lastModifiedDate: Fri Apr 14 2023 17:22:22 GMT+0530 (India Standard Time) {}
    // name: FILENAME.PNG
    // size: 12446 bytes
    // type: "image/png"
    // webkitRelativePath: ""
    try {
      if (files?.length > 0) {
        const total = files?.length || 0;

        let candidateImages =
          (await getSingleRecord(enums.models.candidateImages)) || {};

        let chunkDimension = 0,
          paginatedArray = [[]],
          fileSizeSum = 0; // unlinkedImages: images are uploaded to bucket for premium clients even when the product designNo data doesn't exist.. Such images will be auto-linked when that design's product is added to inventory;

        for (let i = 0; i < files.length; i++) {
          const file = files[i],
            fileSize = Number(file.size) / 1000000; // bytes => mb conversion / 1000000

          if (!(file?.name && file?.type?.match("image"))) {
            reportObj.invalid.push(file);
          } else if (fileSize > payloadLimit) {
            reportObj.large.push(file);
          } else if (fileSizeSum + fileSize < payloadLimit) {
            fileSizeSum += fileSize;
            paginatedArray[chunkDimension] =
              paginatedArray[chunkDimension] || [];

            const isExist = (candidateImages.fileNames || []).find(
              (fileName) => fileName && file?.name === fileName
            );

            if (!isExist) {
              paginatedArray[chunkDimension].push(file);
            } else {
              reportObj.duplicates.push(file);
            }
          } else {
            i--;
            fileSizeSum = 0;
            chunkDimension++;
          }
        }

        let is_first_time = "false";
        if (candidateImages?.fileNames?.length) {
          is_first_time = "false";
        } else {
          is_first_time = "true";
        }

        setDisplay({
          total,
          imageUploadInProgress: true,
          report: reportObj,
        });

        let tempDisplay = {
          total,
          imageUploadInProgress: true,
          report: reportObj,
        };

        for (let j = 0; j < paginatedArray.length; j++) {
          let is_last_chunk = "false";

          if (j === paginatedArray.length - 1) is_last_chunk = "true";
          else is_last_chunk = "false";

          try {
            const res = await chunkImagesUpload(paginatedArray[j]);

            await uploadToMyCatalog(paginatedArray[j]);

            CustomEventEmitter.emit(
              "alert_success",
              `${paginatedArray[j].length} IMAGES uploaded to your Catalog successfully`
            );

            res?.uploadedFiles &&
              res.uploadedFiles.map((uploadedFile) => {
                if (uploadedFile?.location) {
                  reportObj.uploaded.unshift(uploadedFile);
                }
              });
          } catch (err) {
            // unlinkedImages.push({})
            CustomEventEmitter.emit(
              "alert_error",
              "Some got failed in the middle!"
            );
          }

          tempDisplay = {
            ...tempDisplay,
            report: reportObj,
            progress: ((j / paginatedArray.length) * 100).toFixed(2),
          };
          setDisplay({
            ...tempDisplay,
          });
        }

        await pushToCortex(is_first_time);

        await deleteCortexCache();

        const fileNames = reportObj.uploaded.map((file) => file.fileName);

        const cortexImages = await getCortexImages();

        const filteredFileNames = fileNames.filter((fileName) =>
          cortexImages.find(
            (styleNo) =>
              fileName?.replace(/\.[^/.]+$/, "") === styleNo ||
              fileName?.replace(/\.[^/.]+$/, "") === styleNo.split(".")[0]
          )
        );

        if (filteredFileNames.length) {
          candidateImages = await appendFileNames(filteredFileNames);
        }

        const failed = paginatedArray
          .flat()
          .filter(
            (file) =>
              !(filteredFileNames || []).find(
                (fileName) => file.name === fileName
              )
          );

        reportObj.failed = failed;

        reportObj.cortexVectors = candidateImages?.fileNames || [];

        tempDisplay = {
          ...tempDisplay,
          report: reportObj,
        };
        setDisplay({
          ...tempDisplay,
        });
        setTrainedDoc(candidateImages);

        CustomEventEmitter.emit(
          "alert_success",
          `Upload Completed successfully.`
        );

        setDisplay({ ...tempDisplay, imageUploadInProgress: false });
      }
    } catch (err) {
      console.log(err);

      CustomEventEmitter.emit("alert_error", "Something went wrong!");
    } finally {
      setFiles([]);
      setFolder([]);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <MainSection>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>Catalog: {trainedDoc?.fileNames?.length}</h3>
          </Grid>
          <SaveReminder />

          <Grid item xs={6}>
            Files
            <DocUpload
              multiple={true}
              accept="image/png, image/jpeg"
              files={files}
              setFiles={setFiles}
              uploadIcon={{
                type: "Component",
                icon: BurstModeIcon,
                style: { color: "orange", fontSize: 64, cursor: "pointer" },
              }}
              handleUpload={() => handleImagesUpload(Array.from(files))}
            />
          </Grid>
          <Grid item xs={6}>
            Folder
            <DocUpload
              multiple={true}
              accept="image/png, image/jpeg"
              webkitdirectory="true"
              files={folder}
              setFiles={setFolder}
              uploadIcon={{
                type: "Component",
                icon: FolderOpenIcon,
                style: { color: "orange", fontSize: 64, cursor: "pointer" },
              }}
              handleUpload={() => handleImagesUpload(Array.from(folder))}
            />
          </Grid>
        </Grid>
      </MainSection>

      <MainSection style={{ marginTop: "24px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Logs</Typography>
            <p>
              {display.report?.uploaded?.length || 0} / {display.total || 0}
            </p>
          </Grid>

          {display.report?.failed?.length ? (
            <>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleImagesUpload(Array.from(display.report.failed))
                  }
                >
                  Retry {display.report?.failed?.length} failed files
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{ color: "red" }}>
                  Failed{" "}
                  {`(${
                    display.report?.failed?.length
                  }): ${display.report?.failed
                    ?.map((file) => file?.name)
                    .join(", ")}`}
                </Typography>
              </Grid>
            </>
          ) : (
            ""
          )}

          <Grid item xs={12}>
            {display?.imageUploadInProgress && (
              <MuiProgressLine progress={display.progress || 0} />
            )}
          </Grid>

          <Grid item xs={12}>
            <ul>
              <li>Selected Files: {display?.total}</li>

              <li>
                Duplicates{" "}
                {`(${
                  display.report?.duplicates?.length
                }): ${display.report?.duplicates
                  ?.map((file) => file?.name)
                  .join(", ")}`}
              </li>
              <li>
                Large{" "}
                {`(${display.report?.large?.length}): ${display.report?.large
                  ?.map((file) => file?.name)
                  .join(", ")}`}
              </li>

              <li>
                Invalid{" "}
                {`(${
                  display.report?.invalid?.length
                }): ${display.report?.invalid
                  ?.map((file) => file?.name)
                  .join(", ")}`}
              </li>

              <li>
                Cortex Vectors{" "}
                {`(${display.report?.cortexVectors?.length || 0}): ${
                  display.report?.cortexVectors
                    ?.map((fileName) => fileName)
                    .join(", ") || ""
                }`}
              </li>
            </ul>
          </Grid>
        </Grid>
      </MainSection>

      <MainSection style={{ marginTop: "24px" }}>
        <ImageUpProgress reportArray={display?.report?.uploaded || []} />
      </MainSection>
    </FormProvider>
  );

  return <></>;
};

export default TrainingDetail;
