import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@ui/components/AuthProvider";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { defaultGoldHex, isVendor, userDetails } from "@ui/Utils/helper";
import { dynamicApiCall, search } from "@ui/ComponentUtils/blueprintAPIs";
import { Autocomplete } from "@material-ui/lab/index";
import { TextField } from "@material-ui/core/index";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { filterDivisionsWithUserAccess, logout } from "@ui/Auth/api";
import FactoryIcon from "@mui/icons-material/Factory";
import commonConfig from "config/commonConfig";

const constDrawerWidth = 200;

const ResponsiveDrawer = (props) => {
  const {
    children,
    appName,
    secondaryMenus = [],
    primaryMenus = [],
    activeMenuIndex = 0,
    userInfo = {},
  } = props;
  const auth = useAuth();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [activeMenu, setActiveMenu] = React.useState({});
  const [onMenuHover, setOnMenuHover] = React.useState(false);
  const [onIconHover, setOnIconHover] = React.useState({});
  const [divisions, setDivisions] = React.useState([]);
  const [localDivision, setLocalDivision] = React.useState({});
  const location = useLocation();
  const drawerWidth = mobileOpen ? constDrawerWidth : 50;
  const isDivisionControlled =
    userInfo?.client?.premiumClient?.divisionControl ||
    userDetails?.client?.premiumClient?.divisionControl;
  const urlPaths = location.pathname?.toString().split("/");

  useEffect(() => {
    if (isDivisionControlled) {
      (async () => {
        try {
          const res =
            (await dynamicApiCall(
              `${commonConfig.baseApiUrl}data-engine/divisions/search`,
              "post"
            )) || [];

          setDivisions(filterDivisionsWithUserAccess(res));
        } catch (err) {
          console.log(err);
        }
      })();

      const tmpLocalDiv =
        localStorage.getItem("division") &&
        JSON.parse(localStorage.getItem("division"));

      if (tmpLocalDiv?._id) {
        setLocalDivision(tmpLocalDiv);
      }
    }
  }, []);

  useEffect(() => {
    if (
      activeMenu?.hideMenuByDefault &&
      location.pathname
        ?.toString()
        .toLowerCase()
        .match(activeMenu.path?.toLowerCase())
    ) {
      setMobileOpen(false);
    }
  }, [activeMenu, location]);

  useEffect(() => {
    primaryMenus[activeMenuIndex] &&
      setActiveMenu(primaryMenus[activeMenuIndex]);
  }, [activeMenuIndex]);

  useEffect(() => {
    const tempActiveMenu = primaryMenus?.find((menu) =>
      location?.pathname
        ?.toString()
        .toLowerCase()
        .match(menu?.path?.toLowerCase())
    );
    if (tempActiveMenu) {
      setActiveMenu(tempActiveMenu);
    }
  }, [location?.pathname]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!auth.user) return <>{children}</>;

  const AppName = () => {
    return (
      <div>
        <MenuIcon
          style={{
            marginRight: mobileOpen && "24px",
            color: onMenuHover ? "black" : "grey",
            marginTop: "20px",
            paddingLeft: "14px",
          }}
        />
        <span
          style={{
            color: onMenuHover ? "black" : "grey",
          }}
        >
          {mobileOpen && (appName || "App Name")}
        </span>
      </div>
    );
  };

  const drawer = (
    <div>
      <div
        onClick={handleDrawerToggle}
        style={{
          // padding: "24px",
          paddingTop: "8px",
          cursor: "pointer",
        }}
        onMouseEnter={() => setOnMenuHover(true)}
        onMouseLeave={() => setOnMenuHover(false)}
      >
        {mobileOpen ? (
          <Typography variant="h6" noWrap component="div">
            <Tooltip title="Toggle Menu">
              <AppName />
            </Tooltip>
          </Typography>
        ) : (
          <Tooltip title="Toggle Menu">
            <MenuOpenIcon
              style={{
                color: onMenuHover ? "black" : "grey",
                fontSize: 28,
                marginTop: "20px",
              }}
            />
          </Tooltip>
        )}
      </div>

      {mobileOpen ? (
        <>
          <Divider />
          <List>
            {primaryMenus.map((menu, index) =>
              menu.parent ? (
                <h4 key={index}>{menu.title}</h4>
              ) : (
                menu.access && (
                  <ListItem
                    style={{
                      backgroundColor:
                        menu?.title === activeMenu?.title
                          ? "lightgrey"
                          : "white",
                      height: menu?.title === activeMenu?.title ? 48 : 24,
                      padding: "20px",
                      cursor: "ponter",
                    }}
                    key={index}
                    disablePadding
                    onClick={() => {
                      if (mobileOpen && menu?.title === activeMenu?.title) {
                        setMobileOpen(false);
                      }
                      setActiveMenu(menu);
                      navigate(menu.path);
                    }}
                  >
                    <ListItemButton
                      onMouseEnter={() => setOnIconHover(menu)}
                      onMouseLeave={() => setOnIconHover({})}
                    >
                      <ListItemIcon
                        style={
                          activeMenu?.title === menu.title ||
                          onIconHover?.title === menu.title
                            ? { color: "black", fontSize: 30 }
                            : {}
                        }
                      >
                        {activeMenu?.title === menu.title ||
                        onIconHover?.title === menu.title
                          ? menu.selectedIcon
                            ? menu.selectedIcon()
                            : menu.icon()
                          : menu.icon && menu.icon()}
                      </ListItemIcon>
                      <ListItemText primary={menu.title} />
                    </ListItemButton>
                  </ListItem>
                )
              )
            )}
          </List>
          <Divider />
          <List style={{ padding: "20px" }}>
            {secondaryMenus.map(
              (menu, index) =>
                menu.access && (
                  <ListItem
                    key={index}
                    disablePadding
                    onClick={() => navigate(menu.path)}
                  >
                    <ListItemButton>
                      <ListItemIcon>{menu.icon()}</ListItemIcon>
                      <ListItemText primary={menu.title} />
                    </ListItemButton>
                  </ListItem>
                )
            )}

            <h4>{auth.user?.firstName + " " + auth.user?.lastName}</h4>

            <p style={{ fontSize: 12, marginTop: "-10px", color: "grey" }}>
              {auth.user?.role}
            </p>
            {isVendor && (
              <p style={{ fontSize: 12, marginTop: "-10px", color: "grey" }}>
                Codes: {auth.user?.vendorCode?.join(", ")}
              </p>
            )}

            <p style={{ fontSize: 12, marginTop: "-10px", color: "grey" }}>
              {auth.user?.phone}
            </p>
            <p style={{ fontSize: 12, marginTop: "-10px", color: "grey" }}>
              {auth.user?.client?.name}
            </p>

            <div>
              <FactoryIcon
                style={{
                  fontSize: "48px",
                  cursor: "pointer",
                  color: defaultGoldHex,
                }}
                onClick={() => navigate("/home")}
              />
            </div>

            <div style={{ padding: "24px 24px 24px 0" }}>
              {isDivisionControlled && divisions?.length ? (
                <Autocomplete
                  options={divisions || []}
                  size="small"
                  value={localDivision}
                  getOptionLabel={(option) => option?.name || ""}
                  disableClearable
                  onChange={(_, value) => {
                    if (value?._id) {
                      localStorage.setItem("division", JSON.stringify(value));
                      CustomEventEmitter.emit("alert_success", "Updated");
                      setLocalDivision(value);
                      window.location.reload();
                      window.sessionStorage.clear();
                    }
                  }}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      size="small"
                      style={{ width: "100%" }}
                      variant={"outlined"}
                      label={"Division"}
                    />
                  )}
                />
              ) : null}
            </div>

            <ListItem
              disablePadding
              onClick={async () => {
                await logout();
                auth.signout();
                localStorage.clear();
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={"Signout"} />
              </ListItemButton>
            </ListItem>
          </List>
        </>
      ) : (
        <>
          <Divider />
          <List>
            {primaryMenus.map((menu, index) =>
              menu.parent ? (
                <h4 key={index}>{menu.title}</h4>
              ) : (
                menu.access && (
                  <ListItem
                    style={{
                      backgroundColor:
                        menu?.title === activeMenu?.title
                          ? "lightgrey"
                          : "white",
                      height: menu?.title === activeMenu?.title && 48,
                      padding: "12px 0 12px 24px",
                      cursor: "ponter",
                    }}
                    key={index}
                    disablePadding
                    onClick={() => {
                      if (mobileOpen && menu?.title === activeMenu?.title) {
                        setMobileOpen(false);
                      } else {
                        setMobileOpen(!mobileOpen);
                        setActiveMenu(menu);
                        navigate(menu.path);
                      }
                    }}
                  >
                    <ListItemButton
                      onMouseEnter={() => setOnIconHover(menu)}
                      onMouseLeave={() => setOnIconHover({})}
                    >
                      <ListItemIcon
                        style={
                          activeMenu?.title === menu.title ||
                          onIconHover?.title === menu.title
                            ? { color: "black", fontSize: 30 }
                            : {}
                        }
                      >
                        <Tooltip title={menu.title}>
                          {activeMenu?.title === menu.title ||
                          onIconHover?.title === menu.title
                            ? menu.selectedIcon
                              ? menu.selectedIcon()
                              : menu.icon()
                            : menu.icon && menu.icon()}
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                )
              )
            )}
          </List>
        </>
      )}
    </div>
  );

  if (
    urlPaths.length < 3 ||
    urlPaths.find((path) => path?.toLowerCase() === "home")
  ) {
    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100%",
        }}
      >
        {children}
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "white",
                color: "black",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }
};

export default ResponsiveDrawer;
