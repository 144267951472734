import React from "react";

const ReactDocViewer = (props) => {
  const { file, style } = props;
  const officeAppsUrl = "https://view.officeapps.live.com/op/embed.aspx?src=";

  // https://view.officeapps.live.com/op/embed.aspx?src=https://sfs-api-framework-upload-dev.s3.ca-central-1.amazonaws.com/smart-supplier/25fc6629-4cd5-42b3-a470-4e1adce3e430/Dev%20test%20temp.xlsx
  return (
    <div style={{ position: "relative" }}>
      <iframe
        src={officeAppsUrl + file}
        style={{ height: 500, ...style }}
        width={"100%"}
      />
    </div>
  );
};

export default ReactDocViewer;
