import React from "react";

import { Grid, Typography, Paper, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import { defaultLightGoldHex } from "@ui/Utils/helper";

const useStyles = makeStyles((theme) => ({
  rootPaper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // height: "100%",
    // overflowY: "scroll",
  },
  root: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  title: {
    textTransform: "uppercase",
    letterSpacing: "0.5px",
    fontWeight: 600,
  },
  content: {
    paddingTop: theme.spacing(1),
  },
  hideElement: {
    display: "none",
  },
  iconHover: {
    "&:hover": {
      backgroundColor: "#ede8e8",
    },
  },
}));

const Section = (props, ref) => {
  const { title, children, actions, titleContainerStyle } = props;
  const classes = useStyles();
  const rootComponent = props.rootComponent === "div";

  const renderActions = () => {
    if (actions) {
      return actions.map((action, index) => (
        <Action action={action} key={"action-" + index} />
      ));
    }
    return null;
  };
  return (
    <div ref={ref} key={props.id}>
      {rootComponent ? (
        <div
          className={classes.rootPaper}
          style={{ padding: "24px", ...props.style }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={titleContainerStyle}
          >
            <Grid item xs={props.titleXS || 6}>
              {title && (
                <Typography
                  variant="subtitle2"
                  style={{
                    color: defaultLightGoldHex,
                    padding: "5px 0px",
                    ...props?.titleStyle,
                  }}
                >
                  {title}
                </Typography>
              )}
            </Grid>
            <Grid
              container
              item
              xs={props.actionsXS || 6}
              justifyContent="flex-end"
              spacing={1}
              alignItems="center"
            >
              {actions && <>{renderActions()}</>}
            </Grid>
          </Grid>
          <Grid item className={classes.content}>
            {children}
          </Grid>
        </div>
      ) : (
        <Paper className={classes.rootPaper} style={props.style}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={titleContainerStyle}
          >
            <Grid item xs={props.titleXS || 6} lg={props.titleLG || 6}>
              {title && (
                <Typography
                  variant="subtitle2"
                  style={{
                    padding: "5px 0px",
                    color: defaultLightGoldHex,
                    ...props.titleStyle,
                  }}
                >
                  {title}
                </Typography>
              )}
            </Grid>
            <Grid
              container
              item
              xs={props.actionsXS || 6}
              lg={props.actionsLG || 6}
              justifyContent="flex-end"
              spacing={1}
              alignItems="center"
            >
              {actions && <>{renderActions()}</>}
            </Grid>
          </Grid>
          <Grid item className={classes.content}>
            {children}
          </Grid>
        </Paper>
      )}
    </div>
  );
};

export const Action = (props) => {
  const classes = useStyles();
  const { action } = props;

  const handleOnClick = (event) => {
    if (action.onClick) {
      action.onClick(event);
      event.stopPropagation();
    }
  };
  const classNames = action.hidden ? classes.hideElement : "";
  const displayComponent = () => {
    const button =
      action.buttonType === "icon" ? (
        <IconButton
          color={action.color || "secondary"}
          variant={action.variant || "contained"}
          onClick={handleOnClick}
          disabled={action.disabled}
          className={classes.iconHover}
          hidden={action.hidden}
          style={action.style}
        >
          {action.customIcon ? action.customIcon : <Icon>{action.label}</Icon>}
        </IconButton>
      ) : action.buttonType === "custom" ? (
        action.customIcon
      ) : (
        <Button
          color={action.color || "secondary"}
          variant={action.variant || "contained"}
          onClick={handleOnClick}
          disabled={action.disabled}
          hidden={action.hidden}
        >
          <Typography
            color={action.typographyColor || "white"}
            style={action.style}
          >
            {action.label}
          </Typography>
        </Button>
      );

    if (action.tooltip) {
      // fix for issue #1049
      // https://github.com/mbrn/material-table/issues/1049

      return (
        <Tooltip title={action.tooltip}>
          <span>{button}</span>
        </Tooltip>
      );
    } else {
      return button;
    }
  };

  return (
    <Grid item className={classNames}>
      {displayComponent()}
    </Grid>
  );
};

export default React.forwardRef(Section);

// 	{
// 		buttonType: 'icon',
// 		color: 'default',
// 		customIcon: <HoverIcons muiIcon={<AddCircleOutlineIcon />} muiIconHover={<AddCircleIcon />} />,
// 		tooltip: 'Add',
// 		hidden: !values.type || !isEditable,
// 		onClick: () => toggleAddDrawer(),
// 	},
