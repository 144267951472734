import React, { useEffect, useRef } from "react";
import { IconButton } from "@material-ui/core";
import { MuiTooltip } from "@ui/assets/materialUI";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const DocUpload = ({
  hideUploadIcon = false,
  setReadingFiles = () => {},
  files,
  setFiles,
  handleUpload,
  disabled,
  style,
  accept,
  multiple,
  tooltip,
  id,
  uploadIcon,
  // uploadIcon: {
  //   type: "png",
  //   icon: "icon"
  // },
  ...props
}) => {
  const uploadRef = useRef(null);
  const handleFileSelect = (event) => {
    setFiles(event.target.files);
  };

  useEffect(() => {
    if (files?.length > 0) {
      handleUpload();
    }
  }, [files]);

  const renderUploadIcon = () => {
    switch (uploadIcon?.type) {
      case "png":
        return (
          <img
            src={uploadIcon.icon}
            alt={uploadIcon.alt || ""}
            style={{ height: 40, width: 40, ...(uploadIcon.style || {}) }}
          />
        );
      case "Component":
        return <uploadIcon.icon style={uploadIcon.style} />;
      default:
        return <CloudUploadIcon disabled={disabled} />;
    }
  };

  return (
    <div style={style || { display: "flex", alignItems: "center" }}>
      <input
        accept={accept || "*"}
        id="icon-button-photo"
        ref={uploadRef}
        multiple={multiple}
        style={{ display: "none", cursor: "hand" }}
        onChange={handleFileSelect}
        onClick={(event) => {
          event.target.value = null;
          setReadingFiles(true);
        }}
        type="file"
        disabled={disabled}
        {...props}
      />
      {!hideUploadIcon && (
        <label htmlFor="icon-button-photo">
          <MuiTooltip title={tooltip || "Upload"}>
            <IconButton id={id} onClick={() => uploadRef.current.click()}>
              {renderUploadIcon()}
            </IconButton>
          </MuiTooltip>
        </label>
      )}
    </div>
  );
};

export default DocUpload;
