import { checkIfImageExists, userDetails } from "@ui/Utils/helper";
import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";

export const identifyDesign = async (files, top_matches) => {
  try {
    let url = `${commonConfig.microservices.cortex}inference/?client_id=${userDetails?.client?._id}&top_matches=${top_matches}`;

    let formData = new FormData();
    files.map((file) => formData.append("file_upload", file));

    const res = await HTTP.upload(url, files, false, formData);

    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
