import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import _ from "lodash";

import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import enums from "helpers/enums";
import { Grid, Typography } from "@material-ui/core";
import DocUpload from "@ui/Controls/Upload/DocUpload";
import { identifyDesign } from "../api";
import { deleteCortexCache } from "views/Training/api";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import { getSingleRecord } from "@ui/ComponentUtils/blueprintAPIs";
import { getCortexImageUri } from "@ui/Utils/helper";
import { MyAutocomplete, MyCarousel } from "@ui/MuiComponents/index";
import DetailContent from "./components/DetailContent";

const entitySchema = {};

const IdentifyDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [matches, setMatches] = useState([]);
  const top_matches_limit = window.localStorage.getItem(
    enums.localStorage.top_matches_limit
  );

  const [topMatches, setTopMatches] = useState(
    top_matches_limit ? JSON.parse(top_matches_limit) : 50
  );
  const [candidateImages, setCandidateImages] = useState([]);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const res = (await getSingleRecord(enums.models.candidateImages)) || {};

        setCandidateImages(res);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (files.length) {
      handleUpload();
    }
  }, [topMatches]);

  const handleUpload = async () => {
    try {
      const imageURL = URL.createObjectURL(Array.from(files)[0]);
      setImageURL(imageURL);

      const res = await identifyDesign(Array.from(files), topMatches);

      const tempMatches = [];

      (res?.data || []).map((design) => {
        const matchingStyles = [];
        (design.matchingStyles || []).map((styleNo) => {
          let imageFlag = false,
            fileName = styleNo + ".png";

          (candidateImages.fileNames || []).find((eFileName) => {
            if (eFileName?.replace(/\.[^/.]+$/, "") === styleNo) {
              imageFlag = true;
              fileName = eFileName;
              return true;
            }
            // else if (
            //   eFileName?.replace(/\.[^/.]+$/, "") === styleNo?.split?.(".")?.[0]
            // ) {
            //   imageFlag = true;
            //   fileName = eFileName;
            // }
          });

          matchingStyles.push({
            fileName: fileName,
            imageFlag: imageFlag,
            model: "training",
          });
        });
        tempMatches.push({
          croppedImage: "data:image/jpeg;base64," + design.croppedImage,
          matchingStyles,
        });
      });
      setMatches(tempMatches || []);
      await deleteCortexCache();
      CustomEventEmitter.emit(
        "alert_success",
        res?.data?.length + " matches found"
      );
    } catch (err) {
      console.log(err?.response?.data?.message, err);

      CustomEventEmitter.emit(
        "alert_error",
        err?.response?.data?.message || "Something went wrong"
      );
    }
  };

  return candidateImages?.fileNames?.length ? (
    <FormProvider {...formMethods}>
      <MainSection>
        <Grid container spacing={2}>
          <Grid item xs={8} container>
            <Grid item xs={12}>
              <Typography>
                Catalog: {candidateImages?.fileNames?.length}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MyAutocomplete
                options={[10, 30, 50, 100]}
                value={topMatches}
                disableClearable={true}
                onChange={(_, v) => {
                  setTopMatches(v);
                  window.localStorage.setItem(
                    enums.localStorage.top_matches_limit,
                    v
                  );
                }}
                label={"Top Matches"}
              />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Typography>Identify</Typography>
            <DocUpload
              files={files}
              setFiles={setFiles}
              accept={"image/*"}
              handleUpload={() => handleUpload(files)}
              uploadIcon={{
                type: "Component",
                icon: ImageSearchIcon,
                style: { color: "green", fontSize: 64, cursor: "pointer" },
              }}
            />
          </Grid>
        </Grid>
      </MainSection>
      {matches?.length ? (
        <DetailContent matches={matches} imageURL={imageURL} />
      ) : (
        ""
      )}
    </FormProvider>
  ) : (
    <div>
      <h4>Cortex Catalog not found. Upload your Catalog to Identify</h4>
    </div>
  );
};

export default IdentifyDetail;
