import { Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Account } from "../../assets/landingpage/account.svg";
import { ReactComponent as DownArrow } from "../../assets/landingpage/down-arrow.svg";
import { StackStyled } from "./Account.style";
import { logout } from "@ui/Auth/api";

const AccountBox = ({
  stackProps,
  accountIconProps,
  fullName = { firstName: "Ayush", lastName: "Shah" },
}) => {
  return (
    <StackStyled
      flexDirection="row"
      alignItems="center"
      gap="1rem"
      {...stackProps}
    >
      <Account {...accountIconProps} />
      <Typography className="account_name">
        {fullName?.firstName} <br />
        {fullName?.lastName}
      </Typography>
      <DownArrow />
      <b style={{ cursor: "pointer" }} onClick={logout}>
        Logout
      </b>
    </StackStyled>
  );
};

export default AccountBox;
