import { isAdmin, isSuperAdmin, userDetails } from "@ui/Utils/helper";
import enums from "helpers/enums";
import commonConfig from "../../config/commonConfig";
import HTTP from "../Utils/http";

const baseApiUrl = commonConfig.baseApiUrl + `${commonConfig.appModule}/`;
const modelUrl = baseApiUrl + "users";
export const signup = async (userData) => {
  try {
    const url = modelUrl + "/signup";

    const res = await HTTP.post(url, userData);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const login = async (
  userData,
  json = {
    iamLogin: false,
  },
  additionalHeaders = {}
) => {
  try {
    let url;
    if (json.iamLogin) {
      url = commonConfig.baseApiUrl + "iam/users/signin";
    } else {
      url = modelUrl + "/signin";
    }

    const res = await HTTP.post(url, userData, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const logout = async () => {
  try {
    const url = modelUrl + "/signout";

    const res = await HTTP.post(url);

    localStorage.clear();
    window.location.href = "/";
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const resetPassword = async (
  userData = {
    phone: "", // without +91
    password: "",
  }
) => {
  try {
    const url = modelUrl + "/resetPassword";

    const res = await HTTP.post(url, userData);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const sendPhoneOtp = async (
  phone,
  { throwIfUserExist, client } //
) => {
  try {
    const url = modelUrl + "/sendPhoneOTP";

    const res = await HTTP.post(url, { phone, throwIfUserExist, client });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyOtp = async (phone, code) => {
  try {
    const url = modelUrl + "/verifyPhoneOTP";

    const res = await HTTP.post(url, { phone, code });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const filterDivisionsWithUserAccess = (divisions, userParams = {}) => {
  return (divisions || []).filter(
    (div) =>
      isAdmin ||
      isSuperAdmin ||
      userParams.role === enums.roles.admin ||
      userParams.role === "Super Admin" ||
      (userParams.division || userDetails.division || []).find(
        (userDiv) => userDiv?._id === div?._id
      )
  );
};
